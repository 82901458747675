import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";

// history 关键字：createWebHistory
// hash 关键字：createWebHashHistory

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "论文助手",
      content: {
        keywords: "论文助手|论文写作|AI写作|写论文|论文挤水|精简论文|论文降重",
        description: "本网站汇聚尖端AI技术，通过智能话术引导，精准捕捉学术需求，高效生成高质量论文。我们致力于打造学术创新的智能平台，为研究者提供无与伦比的便捷与效率。在这里，每一篇论文都承载着智慧的光芒，引领着学术的潮流。",
      },
    },
  },
  // {
  //   path: "/xlw/tj",
  //   name: "/xlw/tj",
  //   component: () => import("@/views/xielunwen/tijiaobiaoti.vue"),
  //   meta: {
  //     title: "智言论文",
  //     content: {
  //       keywords: "",
  //       description: "",
  //     },
  //   },
  // },
  // {
  //   path: "/lunwen",
  //   name: "/lunwen",
  //   component: () => import("@/views/xielunwen/index.vue"),
  //   meta: {
  //     title: "慧语成章",
  //     content: {
  //       keywords: "",
  //       description: "",
  //     },
  //   },
  // },
  // {
  //   path: "/gdls",
  //   name: "/gdls",
  //   component: () => import("@/views/xielunwen/guandianlunshu.vue"),
  //   meta: {
  //     title: "观澜论道",
  //     content: {
  //       keywords: "",
  //       description: "",
  //     },
  //   },
  // },
  // {
  //   path: "/mwfy",
  //   name: "/mwfy",
  //   component: () => import("@/views/xielunwen/mowufengyun.vue"),
  //   meta: {
  //     title: "墨舞风云",
  //     content: {
  //       keywords: "",
  //       description: "",
  //     },
  //   },
  // },
  // {
  //   path: "/lwxt",
  //   name: "/lwxt",
  //   component: () => import("@/views/xuantibaogao/index.vue"),
  //   meta: {
  //     title: "论文选题",
  //     content: {
  //       keywords: "",
  //       description: "选题报告简易版",
  //     },
  //   },
  // },
  // {
  //   path: "/lwxt/ty",
  //   name: "/lwxt/ty",
  //   component: () => import("@/views/xuantibaogao/tiyanban.vue"),
  //   meta: {
  //     title: "选题体验版",
  //     content: {
  //       keywords: "",
  //       description: "",
  //     },
  //   },
  // },
  // {
  //   path: "/xtbg",
  //   name: "/xtbg",
  //   component: () => import("@/views/xuantibaogao/baogao.vue"),
  //   meta: {
  //     title: "选题报告",
  //     content: {
  //       keywords: "",
  //       description: "选题报告复杂版",
  //     },
  //   },
  // },
  {
    path: "/login",
    name: "/login",
    component: () => import("@/views/user/login.vue"),
    meta: {
      title: "登录",
      content: {
        keywords: "",
        description: "",
      },
    },
  },
  {
    path: "/registered",
    name: "/registered",
    component: () => import("@/views/user/registered.vue"),
    meta: {
      title: "注册",
      content: {
        keywords: "",
        description: "",
      },
    },
  },
  {
    path: "/czpsw",
    name: "/czpsw",
    component: () => import("@/views/user/chongzhimima.vue"),
    meta: {
      title: "重置密码",
      content: {
        keywords: "",
        description: "",
      },
    },
  },
  {
    path: "/grzx",
    name: "/grzx",
    component: () => import("@/views/user/gerenzhongxin.vue"),
    meta: {
      title: "个人中心",
      content: {
        keywords: "",
        description: "",
      },
    },
  },
  {
    path: "/account",
    name: "/account",
    component: () => import("@/views/user/account/index.vue"),
    meta: {
      title: "账户信息",
      content: {
        keywords: "",
        description: "",
      },
    },
  },
  // {
  //   path: "/xzjl",
  //   name: "/xzjl",
  //   component: () => import("@/views/user/xiezuojilu.vue"),
  //   meta: {
  //     title: "智言论文",
  //     content: {
  //       keywords: "",
  //       description: "",
  //     },
  //   },
  // },
  // {
  //   path: "/xtjl",
  //   name: "/xtjl",
  //   component: () => import("@/views/user/xuanti/xuantibaogaojilu.vue"),
  //   meta: {
  //     title: "文章大纲",
  //     content: {
  //       keywords: "",
  //       description: "",
  //     },
  //   },
  // },
  // {
  //   path: "/xtbg/jzsj",
  //   name: "/xtbg/jzsj",
  //   component: () => import("@/views/xuantibaogao/jingzhunshuju.vue"),
  //   meta: {
  //     title: "精准数据",
  //     content: {
  //       keywords: "",
  //       description: "",
  //     },
  //   },
  // },
  // {
  //   path: "/xtbg/jzsjjl",
  //   name: "/xtbg/jzsjjl",
  //   component: () => import("@/views/user/xuanti/jingzhunshuju.vue"),
  //   meta: {
  //     title: "精准数据",
  //     content: {
  //       keywords: "",
  //       description: "",
  //     },
  //   },
  // },
  // {
  //   path: "/lwjl",
  //   name: "/lwjl",
  //   component: () => import("@/views/user/lunwen/lunwenjilu.vue"),
  //   meta: {
  //     title: "慧语成章",
  //     content: {
  //       keywords: "",
  //       description: "",
  //     },
  //   },
  // },
  // {
  //   path: "/gdlsjl",
  //   name: "/gdlsjl",
  //   component: () => import("@/views/user/lunwen/guandianlunshujilu.vue"),
  //   meta: {
  //     title: "观澜论道",
  //     content: {
  //       keywords: "",
  //       description: "",
  //     },
  //   },
  // },
  // {
  //   path: "/mwfyjl",
  //   name: "/mwfyjl",
  //   component: () => import("@/views/user/lunwen/mowufengyunjilu.vue"),
  //   meta: {
  //     title: "墨舞风云",
  //     content: {
  //       keywords: "",
  //       description: "",
  //     },
  //   },
  // },
  // {
  //   path: "/jsbg/lczy",
  //   name: "/jsbg/lczy",
  //   component: () => import("@/views/jishubaogao/guangxi.vue"),
  //   meta: {
  //     title: "广西卫生",
  //     content: {
  //       keywords: "",
  //       description: "",
  //     },
  //   },
  // },
  // {
  //   path: "/jsbg/lczyjl",
  //   name: "/jsbg/lczyjl",
  //   component: () => import("@/views/user/jishubaogao/guangxi.vue"),
  //   meta: {
  //     title: "广西卫生",
  //     content: {
  //       keywords: "",
  //       description: "",
  //     },
  //   },
  // },
  // {
  //   path: "/hzlw",
  //   name: "/hzlw",
  //   component: () => import("@/views/user/admin/huizonglunwen.vue"),
  //   meta: {
  //     title: "论文汇总",
  //     content: {
  //       keywords: "",
  //       description: "",
  //     },
  //   },
  // },
  // {
  //   path: "/cc",
  //   name: "/cc",
  //   component: () => import("@/views/user/admin/chachong.vue"),
  //   meta: {
  //     title: "查重",
  //     content: {
  //       keywords: "",
  //       description: "",
  //     },
  //   },
  // },

  // 新增功能页面
  {
    path: "/wenzhangxuanti",
    name: "/wenzhangxuanti",
    component: () => import("@/views/new/xuanti/index.vue"),
    meta: {
      title: "文章选题",
      content: {
        keywords: "",
        description: "",
      },
    },
  },
  {
    path: "/wenzhangdagang",
    name: "/wenzhangdagang",
    component: () => import("@/views/new/wenzhangdagang/index.vue"),
    meta: {
      title: "文章大纲",
      content: {
        keywords: "",
        description: "",
      },
    },
  },
  {
    path: "/xiezuoshili",
    name: "/xiezuoshili",
    component: () => import("@/views/new/xiezuoshili/index.vue"),
    meta: {
      title: "写作示例",
      content: {
        keywords: "",
        description: "",
      },
    },
  },
  {
    path: "/xiezuo3",
    name: "/xiezuo3",
    component: () => import("@/views/new/xiezuoshili3/index.vue"),
    meta: {
      title: "写作示例3",
      content: {
        keywords: "",
        description: "",
      },
    },
  },
  // {
  //   path: "/xzjl/xzsl/qt",
  //   name: "/xzjl/xzsl/qt",
  //   component: () => import("@/views/xiezuojilunew/xiezuoshili/qita.vue"),
  //   meta: {
  //     title: "写作示例",
  //     content: {
  //       keywords: "",
  //       description: "",
  //     },
  //   },
  // },
  // {
  //   path: "/xzjl/xzsl/kp",
  //   name: "/xzjl/xzsl/kp",
  //   component: () => import("@/views/xiezuojilunew/xiezuoshili/kepu.vue"),
  //   meta: {
  //     title: "写作示例",
  //     content: {
  //       keywords: "",
  //       description: "",
  //     },
  //   },
  // },
  // {
  //   path: "/xzjl/xzsl/yl",
  //   name: "/xzjl/xzsl/yl",
  //   component: () => import("@/views/xiezuojilunew/xiezuoshili/yiliao.vue"),
  //   meta: {
  //     title: "写作示例",
  //     content: {
  //       keywords: "",
  //       description: "",
  //     },
  //   },
  // },
  {
    path: "/jishubaogao",
    name: "/jishubaogao",
    component: () => import("@/views/new/jishubaogao/index.vue"),
    meta: {
      title: "技术报告",
      content: {
        keywords: "",
        description: "",
      },
    },
  },
  // {
  //   path: "/xzjl/jsbg",
  //   name: "/xzjl/jsbg",
  //   component: () => import("@/views/xiezuojilunew/jishubaogao/index.vue"),
  //   meta: {
  //     title: "技术报告",
  //     content: {
  //       keywords: "",
  //       description: "",
  //     },
  //   },
  // },
  // {
  //   path: "/xzjl/wzdg",
  //   name: "/xzjl/wzdg",
  //   component: () => import("@/views/xiezuojilunew/wenzhangdagang/index.vue"),
  //   meta: {
  //     title: "文章大纲",
  //     content: {
  //       keywords: "",
  //       description: "",
  //     },
  //   },
  // },
  {
    path: "/xiezuojilu",
    name: "/xiezuojilu",
    component: () => import("@/views/xiezuojilunew/index.vue"),
    meta: {
      title: "写作记录",
      content: {
        keywords: "",
        description: "",
      },
    },
  },
  {
    path: "/xuantidanye",
    name: "/xuantidanye",
    component: () => import("@/views/new/xuantidanye/index.vue"),
    meta: {
      title: "医题宝鉴",
      content: {
        keywords: "",
        description: "",
      },
    },
  },


];

const router = createRouter({
  // 更改history模式
  // history: createWebHashHistory(process.env.BASE_URL),
  history: createWebHistory(),
  routes,
});

export default router;
