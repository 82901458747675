import axios from "axios";
import { ElMessage, ElMessageBox } from "element-plus";
import router from "@/router";

// 设置基础路径
const baseURL = "https://xlwapi.yuanpingjia.com.cn";
// const baseURL = "";

// 创建一个 axios 实例
const requests = axios.create({
  baseURL: baseURL, // 所有的请求地址前缀部分
  timeout: 300 * 1000, // 请求超时时间毫秒
});

// 添加请求拦截器
requests.interceptors.request.use((config) => {
  // 添加token
  config.headers.Authorization =
    "Bearer " + window.localStorage.getItem("token") || "";
  // config.headers["Content-type"] = "application/json;charset=UTF-8";
  return config;
});

// 添加响应拦截器
requests.interceptors.response.use(
  (response: any) => {
    // 211未支付状态，单独处理
    if (response.data.code === 200 || response.data.code === 211) {
      return response;
    // 210余额不足，需要到对应页面单独处理
    } else if(response.data.code === 210){
      return Promise.reject(response);
    }else {
      let fkinfotemp = response.data.msg || response.data.message;
      ElMessageBox.alert(fkinfotemp, "提示", {
        confirmButtonText: "我知道了",
      });
      return Promise.reject(response);
    }
  },
  (error) => {
    // 超时处理
    if (error.message.includes("timeout")) {
      // 判断请求异常信息中是否含有超时timeout字符串
      ElMessageBox.alert("请求超时，请刷新页面重试！", "提示", {
        confirmButtonText: "好的",
      });
    } else if (error.response.status === 401) {
      // 清除token
      window.localStorage.removeItem("token");
      // 清除个人信息
      window.localStorage.removeItem("username");
      window.localStorage.removeItem("userInfo");
      // 清除url
      window.localStorage.removeItem("loginurl");
      // 保存当前地址以便登录后返回
      window.localStorage.setItem("loginurl", window.location.href);
      ElMessage({
        message: "请登录后操作！",
        type: "error",
        duration: 1000,
        onClose: () => {
          router.push({
            path: "/login",
            query: {},
          });
        },
      });
    } else {
      // 通用提示
      ElMessageBox.alert(
        "请求错误，请检查网络后刷新页面重试！错误信息：" + error.message,
        "提示",
        {
          confirmButtonText: "好的",
        }
      );
    }

    return Promise.reject(error);
  }
);

export { requests };
