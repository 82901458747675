<template>
  <div class="main">
    <!-- loading -->
    <div
      class="loadings"
      v-if="loading"
      v-loading="{ visible: loading, text: loadingtext }"
    ></div>

    <div class="ny-zhuti">
      <div class="ny-neirong">
        <div class="ny-con">
          <div class="tjlw">
            
            <div class="tjlw-title">医题宝鉴</div>
            <el-form
              :label-position="'top'"
              label-width="200px"
              :model="form"
              style="max-width: 800px"
            >
              <el-form-item label="单位" class="biaotiyouhua-item">
                <el-input
                  v-model="title.danwei"
                  placeholder="必填，请输入您的单位，例：南宁市人民医院"
                />
              </el-form-item>
              <el-form-item label="科室" class="biaotiyouhua-item">
                <el-input
                  v-model="title.keshi"
                  placeholder="必填，请输入您的科室，例：外科"
                />
              </el-form-item>
              <el-form-item label="职称" class="biaotiyouhua-item">
                <el-input
                  v-model="title.zhicheng"
                  placeholder="必填，请输入您的职称，例：副主任医师"
                />
              </el-form-item>
              <el-form-item label="研究方向" class="biaotiyouhua-item">
                <el-input
                  v-model="title.yanjiufangxiang"
                  placeholder="必填，请输入研究方向，例：腹腔镜取石术"
                />
              </el-form-item>
              <el-form-item label="研究时间" class="biaotiyouhua-item">
                <el-input
                  v-model="title.yanjiushijian"
                  placeholder="选填，请输入研究时间，例：2022年6月-2024年6月"
                />
              </el-form-item>
              <el-form-item label="研究对象年龄" class="biaotiyouhua-item">
                <el-input
                  v-model="title.yanjiunianling"
                  placeholder="选填，请输入研究年龄，例：27-63岁"
                />
              </el-form-item>
              <el-form-item label="研究病例数" class="biaotiyouhua-item">
                <el-input
                  v-model="title.yanjiubingli"
                  placeholder="选填，请输入研究病例数，例：收治150例患者，选取100例病例资料作为研究对象"
                />
              </el-form-item>
              <el-form-item label="研究对象性别" class="biaotiyouhua-item">
                <el-input
                  v-model="title.yanjiuxingbie"
                  placeholder="选填，请输入研究性别，例：男54例、女46例"
                />
              </el-form-item>
              <el-form-item label="涉及设备" class="biaotiyouhua-item">
                <el-input
                  v-model="title.shebei"
                  placeholder="选填，请输入研究中所涉及的设备，例：CT扫描仪、核磁共振成像设备（MRI）、数字减影血管造影机（DSA）"
                />
              </el-form-item>
              <el-form-item label="涉及药品" class="biaotiyouhua-item">
                <el-input
                  v-model="title.yaopin"
                  placeholder="选填，请输入研究中所涉及的药品，例：对乙酰氨基酚"
                />
              </el-form-item>
              <el-form-item label="临床资料" class="biaotiyouhua-item">
                <el-input
                  rows="3"
                  type="textarea"
                  v-model="title.linchuangziliao"
                  placeholder="请输入临床资料，长度5000字以内"
                />74
              </el-form-item>
              <el-form-item label="选择分组">
                <el-radio-group v-model="moban.moban">
                  <el-radio
                    v-for="(item, key) in moban.options"
                    :key="key"
                    :value="item.mbid"
                    :label="item.mbname"
                  ></el-radio>
                </el-radio-group>
              </el-form-item>

              <div class="tjlw-btns">
                <el-button size="large" type="primary" @click="lijishengcheng"
                  >立即生成</el-button
                >
              </div>
              
              <el-form-item
                class="titleClass"
                label="选择标题"
                v-if="title.titles.length>0"
              >
                <el-checkbox-group v-model="title.title"  @change="clickxuanzebiaoti">
                  <el-checkbox 
                  v-for="(item,key) in title.titles" 
                  :key="key" 
                  border 
                  :label="item"
                  :value="item">
                    {{item}}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item label="标题">
                <el-input type="textarea" rows="3" v-model="title.xinashititle"/>
              </el-form-item>

                <div class="ckwx-btn">
                  <el-button type="primary" @click="clickdownload"
                    >导出选题</el-button
                  >
                </div>
            </el-form>
          </div>
        </div>
      </div>
    </div>

    
  <newfooter />

  </div>
</template>

<script setup>
import {
  Delete,
  Edit,
  Search,
  Share,
  TrophyBase,
  Refresh,
} from "@element-plus/icons-vue";
import { post, get } from "@/utils/index";
import { ElMessage, ElMessageBox } from "element-plus";
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();

// loading
let loading = ref(false);
let loadingtext = ref("生成中，请等待...");
let randsum = 0;
let form = reactive({});

// 模板
let moban = reactive({
  id: 1,
  moban: "对照组、观察组",
  options: [
    {
      mbid:1,
      mbname:'对照组、观察组',
    },
    {
      mbid:2,
      mbname:'对照组、治疗组',
    },
    {
      mbid:3,
      mbname:'常规组、手术组',
    },
    {
      mbid:4,
      mbname:'其他',
    },
  ],
});
// 标题
let title = reactive({
  danwei: "", 
  keshi:'',
  zhicheng:'',
  yanjiufangxiang:'',
  yanjiushijian:'',
  yanjiunianling:'',
  yanjiubingli:'',
  yanjiuxingbie:'',
  shebei:'',
  yaopin:'',
  linchuangziliao:'',
  title:[],
  titles:[],
  xinashititle:'',
  opt:'',
});


// 获取标题
const huoqubiaoti = async () => {
  if (title.danwei.trim() == "" || title.keshi.trim() == "" || title.zhicheng.trim() == "" || title.yanjiufangxiang.trim() == "") {
    ElMessage({
      message: "单位、科室、职称、研究方向不能为空",
      duration: 1000,
    });
    loading.value = false;
    return;
  }

  // 拼格式
  title.opt=`一、个人信息
            单位：${title.danwei}。
            科室：${title.keshi}。
            目前职称：${title.zhicheng}。
            研究方向：${title.yanjiufangxiang}。

            二、临床资料1
            研究时间:${title.yanjiushijian}。
            年龄: ${title.yanjiunianling}。
            病例数：${title.yanjiubingli}。
            性别:${title.yanjiuxingbie}。
            设备:${title.shebei}。
            药品:${title.yaopin}。

            三、临床资料2
            临床资料：${title.linchuangziliao}。

            分组：${moban.moban}。`

  return post("http://server-1:8055/api/Kw/GetTitleTjByhsId", {
  // return post("/api/Kw/GetTitleTjByhsId", {
    sentence: title.opt,
    bztype:'xuanti',
    num:0,
  })
    .then((res) => {
      // console.log(res.data)
      title.titles.length=0
      title.title.length=0
      // 替换\n\n为\n
      let temptitle2 = res.data.replace(/\n\n/g, "\n");
      title.titles.push(...temptitle2.split("\n"));
      // 默认选择
      title.title.push(title.titles[0])
      clickxuanzebiaoti()
    })
    .catch((err) => {
      loading.value = false;
      return Promise.reject({
        isNotErrorExpection: true,
      });
    });
};


// 开始生成
const lijishengcheng = async () => {

  loading.value = true;
  loadingtext.value = "正在生成中，当前进度0%，请耐心等待...";
  // 初始化
  randsum = 0;
  let conouttime = 60 + Math.floor(Math.random() * 5) + 1;
  loadingfun(conouttime);

  await huoqubiaoti()

  loading.value = false;
};

// 选择标题
const clickxuanzebiaoti=()=>{
  title.xinashititle=''

  title.title.forEach((e,i)=>{
    if(i==title.title.length-1){
      title.xinashititle+=e
    }else{
      title.xinashititle+=e+'\n'
    }
  })
}

// 下载word
const clickdownload = () => {
  if (title.xinashititle == "") {
    ElMessage({
      message: "请生成后再导出",
      type: "warning",
    });
    return;
  }
  loading.value = true;

  let texttemp=`标题：
  ${title.xinashititle}。`+'\n\n'+title.opt
  
  post("http://server-1:8055/api/Word/GetWord", {
  // post("/api/Word/GetWord", {
    text: texttemp,
    title: title.xinashititle.split('\n')[0],
  })
    .then((res) => {
      loading.value = false;
      // let downloadurl = "" + res.data;
      let downloadurl = "http://server-1:8055" + res.data;
      const link = document.createElement("a");
      link.setAttribute("href", downloadurl); //设置下载文件的url地址
      link.click();
    })
    .catch((err) => {
      loading.value = false;
    });
};

// loading
const loadingfun = (outtime) => {
  let randNumtemp = Math.floor(Math.random() * 5) + 1;
  if (loading.value) {
    setTimeout(() => {
      randsum += randNumtemp;
      if (randsum < outtime) {
        let temprandnum = ((randsum / outtime) * 100).toFixed(2);
        loadingtext.value = `正在生成中，当前进度${temprandnum}%，请耐心等待...`;
        loadingfun(outtime);
      } else {
        loadingtext.value = `正在生成中，当前进度99.99%，请耐心等待...`;
      }
    }, randNumtemp * 1000);
  }
};
</script>



<style scoped>
:deep(.el-checkbox) {
  white-space: normal;
}
:deep(.el-checkbox){
  margin-bottom: 10px;
}
:deep(.el-input-group__prepend .el-button) {
  color: var(--el-button-text-color);
  background-color: var(--el-button-bg-color);
  border-color: var(--el-button-border-color);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
:deep(.el-input-group__append .el-button) {
  color: var(--el-button-text-color);
  background-color: var(--el-button-bg-color);
  border-color: var(--el-button-border-color);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

:deep(.el-checkbox) {
  white-space: normal;
}
:deep(.el-form-item__label) {
  font-weight: bold;
}
:deep(.tjlw-btns .el-button) {
  width: 200px;
  height: 50px;
  font-size: 16px;
}
.main {
  width: 100%;
  height: 100%;
  background: #fff;
  min-width: 1200px;
}

.ny-zhuti {
  position: relative;
  width: 100%;
  min-height: 600px;
  height: calc(100% - 50px);
}

/* 流程 */
.ny-liucheng {
  position: absolute;
  left: 200px;
  top: 0;
  width: 400px;
  height: 100%;
  overflow-y: scroll;
  background: url("/img/common/bg-side.9a695b46.png") no-repeat;
  background-size: 100% 100%;
}
.ny-liucheng-title {
  margin-top: 82px;
  margin-bottom: 56px;
  font-size: 28px;
  color: #48a4ff;
  text-align: center;

  opacity: 1;
}
.ny-liucheng-li-title {
  width: 320px;
  height: 38px;
  background: linear-gradient(
    270deg,
    rgba(218, 249, 248, 0.6),
    rgba(179, 225, 251, 0.6)
  );
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  border: 1px solid hsla(0, 0%, 100%, 0.51);
  display: flex;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  padding-left: 12px;
  font-weight: bold;
}
.ny-liucheng-li {
  width: 320px;
  height: auto;
  font-size: 14px;
  background: hsla(0, 0%, 100%, 0.3);
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  border: 1px solid hsla(0, 0%, 100%, 0.6);
  margin: 15px auto 20px;
}
.ny-liucheng-li-title-icon {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: linear-gradient(147deg, #48b6ff, #4891ff);
  color: #fff;
  font-size: 12px;
  text-align: center;
  line-height: 18px;
  margin-right: 12px;
}

.ny-neirong {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.ny-nav {
  width: 1200px;
  height: 60px;
  margin: auto;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #888;
}

.ny-nav a:link,
.ny-nav a:visited {
  color: #888;
  text-decoration: none;
}
.ny-nav a:hover {
  color: #2489f2;
}
.ny-con {
  width: 1200px;
  height: auto;
  background: #fff;
  padding: 30px;
  margin: auto;
  box-sizing: border-box;
  margin-bottom: 50px;
}
.tjlw {
  width: 800px;
  margin: auto;
}
.tjlw-title {
  font-size: 30px;
  font-weight: 400;
  color: #3f4654;
  line-height: 42px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.tjlw-img {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tjlw-img-txt {
  position: absolute;
  left: calc(50% - 400px);
  top: 36px;
  z-index: 1;
  width: 800px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}
.biaotiyouhua-item {
  position: relative;
}
.biaotiyouhua {
  position: absolute;
  left: 0;
  top: 35px;
  width: 800px;
  height: auto;
  padding: 20px;
  box-sizing: border-box;
  font-size: 12px;
  background: #fff;
  box-shadow: 0 6px 20px 1px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  border: 1px solid #ececee;
  z-index: 10;
}
.biaotiyouhua-title {
  display: flex;
  align-items: center;
}
.biaotiyouhua-bt {
  padding: 2px 0;
}
.biaotiyouhua-bts {
  height: 32px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  border-radius: 4px;
  cursor: pointer;
}
.biaotiyouhua-bts:hover {
  background: #f1f5ff;
  color: #2489f2;
}
.biaotiyouhua-hyp {
  width: 70px;
  cursor: pointer;
  padding: 0 0 0 20px;
  display: flex;
  align-items: center;
  color: #2489f2;
}
.tjlw-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0 20px 0;
}
.cankaowx {
  width: 800px;
  height: 328px;
  overflow: hidden;
  padding: 6px 20px;
  box-sizing: border-box;
  font-size: 12px;
  background: #f7f7f7;
  border-radius: 4px;
}
.cankaowx2 {
  width: 800px;
  height: auto;
  padding: 6px 20px;
  box-sizing: border-box;
  font-size: 12px;
  background: #f7f7f7;
  border-radius: 4px;
}
.cankaowx-more {
  width: 100%;
  font-size: 14px;
  text-align: center;
}
.cankaowx-more > span {
  cursor: pointer;
  color: #e6a23c;
}
.cankaowx-more > span:hover {
  text-decoration: underline;
}

.loadings {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
}
.tjlw-btns-link {
  margin-left: 40px;
  font-size: 14px;
  color: #2489f2;
  cursor: pointer;
}
.tjlw-btns-link:hover {
  text-decoration: underline;
}
.ckwx-btn {
  padding: 10px 0 0 0;
}
.tips{
  color: #999;
  font-size: 14px;
}

/* 美化滚动条 */
::-webkit-scrollbar {
  width: 6px;
  height: 10px;
}

::-webkit-scrollbar-track {
  width: 6px;
  background: rgba(#101f1c, 0.1);
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(144, 147, 153, 0.1);
  background-clip: padding-box;
  min-height: 28px;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius: 2em;
  transition: background-color 0.3s;
  cursor: pointer;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(144, 147, 153, 0.3);
}
</style>
