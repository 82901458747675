<template>
  <div class="comfooter">
      <div class="comfooter-list">渝公网安备：50011902000454号</div>
      <div class="comfooter-list">ICP备案号：渝ICP备2023002303号</div>
      <div class="comfooter-list">重庆域田数据科技有限公司©版权所有</div>
    </div>
</template>

<script>
var _hmt = _hmt || [];
(function() {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?dee633eba9840d409c4256e44259a38a";
  var s = document.getElementsByTagName("script")[0]; 
  s.parentNode.insertBefore(hm, s);
})();
</script>
<style scoped>
/* 公共footer */
.comfooter{
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #999;
}
.comfooter-list{
  margin: 0 30px;
}
</style>
